import React from "react"
import styled from "@emotion/styled"

const Frame = styled.iframe`
  border: 0;
`

const Chat = ({ chatSrcURL, chatTitle, ...props }) => (
  <Frame
    frameborder="0"
    scrolling="yes"
    id="groove_cafe"
    src="https://www.twitch.tv/embed/hebo/chat?channel=groove_cafe&parent=groove.cafe"
    height="100%"
    width="100%"
  />
)

export default Chat
