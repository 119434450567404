import React from "react"
import { graphql } from "gatsby"

import styled from "@emotion/styled"

import Layout from "../components/layout"
import Chat from "../components/twitchchat"
import colors from "../components/colors"
import SEO from "../components/seo"
import DonateButton from "../components/donateButton"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.div`
  padding: 2rem;
`

const CardOutline = styled.div`
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  background-color: ${colors.lightyellow};
  box-shadow: -20px 20px ${colors.navy};
  max-width: 100%;
  max-height: 100%;
`

const Title = styled.h1`
  font-family: Messapia Bold;
  font-size: 100%;
`

const LiveFlex = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
  ${mq[2]} {
    display: grid;
    grid-template-columns: 3fr, 1fr;
    grid-auto-rows: auto;
  }
`

const VideoBox = styled.div`
  width: 100%;
  ${mq[2]} {
    width: 580px;
    height: 100%;
    display: grid;
    grid-column: 1 / 4;
  }
`

const ChatBox = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
  padding: 0 1rem;
  ${mq[2]} {
    max-width: 400px;
    height: 90%;
    grid-column: 4 / 5;
  }
`

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html } = markdownRemark

  const siteTitle = " | Groove Café"
  const name = "livestream".concat(siteTitle)

  return (
    <Layout>
      <SEO  title={name}/>
      <Wrapper>
        <CardOutline>
          <LiveFlex>
            <VideoBox>
              <Title>{markdownRemark.frontmatter.title}</Title>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <DonateButton />
            </VideoBox>
            <ChatBox>
              <Chat />
            </ChatBox>
          </LiveFlex>
        </CardOutline>
      </Wrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
