import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const DonateFragment = styled.fragment`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 1rem 0;
`

const Button = styled.button`
  background: transparent;
  border-style: none;
  margin-right: auto;
  left: 0;
`

const Text = styled.p`
  margin: 0 0;
`

const DonateButton = () => {
  return(
    <DonateFragment>
      <Button>
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_donations" />
          <input type="hidden" name="business" value="6T9A7KRCPQXDS" />
          <input type="hidden" name="currency_code" value="USD" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </Button>
      <Text>
        Suggested $10 donation.
        <br />
        <br />
        Sign up for the <Link to="../email-signup">Groove Café mailing list</Link>.
      </Text>
    </DonateFragment>
  )
}

export default DonateButton
